import Config from "./pr_config.json";

export function getDateInYYYYMMDD(dateObject) {
    return dateObject.getFullYear().toString() + "-" + (dateObject.getMonth() + 1).toString().padStart(2, "0") + "-" +
        dateObject.getDate().toString().padStart(2, "0");

}
export function load_fetch(relative_url, postData, callback, textOrJson='json') {
    let url = Config.api_url + relative_url;

    const options = {
        method: 'POST',
        body: JSON.stringify(postData),
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    if(textOrJson === 'json') {
        fetch(url, options)
            .then(response => {
                if(!response.ok) {
                    throw new Error("Failed with HTTP code " + response.status);
                }
                return response;
            })
            .then(result => result.json())
            .then(function (data) {
                callback(data);
            })
            .catch(error => {
                callback({error: error});
            });
    } else {
        fetch(url, options)
            .then(result => result.text())
            .then(function (data) {
                callback(data);
            })
            .catch(error => {
                callback({error: error});
            });
    }
}
export function number_to_month_name(month_number) {
    switch(month_number) {
        case "1":
            return "January";
            break;
        case "2":
            return "February";
            break;
        case "3":
            return "March";
            break;
        case "4":
            return "April";
            break;
        case "5":
            return "May";
            break;
        case "6":
            return "June";
            break;
        case "7":
            return "July";
            break;
        case "8":
            return "August";
            break;
        case "9":
            return "September";
            break;
        case "10":
            return "October";
            break;
        case "11":
            return "November";
            break;
        case "12":
            return "December";
            break;
        default:
            return month_number;
            break;
    }
}
export function date_to_ordinal(date_number, use_the) {
    if(!date_number) {
        return '';
    }
    date_number = date_number.toString().trim();

    if(date_number.charAt(date_number.length - 1) == '1') {
        return use_the + date_number + "st"
    } else if(date_number.charAt(date_number.length - 1) == '2') {
        return use_the + date_number + "nd"
    } else if(date_number.charAt(date_number.length - 1) == '3') {
        if(date_number == '13') {
            return use_the + date_number + "th";
        } else {
            return use_the + date_number + "rd"
        }
    } else {
        return use_the + date_number + "th"
    }
}
