import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";
import PastorPhone from "./images/pastors_phone.jpg";

const ContactUs = () => {

    return (<div>
        <div>
            <div className={Styles.pr_heading_medium}>Contact Information</div>
            <div className={`${Styles.pr_block_quote} ${Styles.pr_font_16}`}>
                <p><span className={Styles.pr_bold}>Pastor</span> Michael Turner</p>
                <p><span className={Styles.pr_bold}>Email: </span><Link to="mailto:theprairieridge@gmail.com">theprairieridge@gmail.com</Link></p>
                <p><span className={Styles.pr_bold}>Pastor's Phone: </span> <img src={PastorPhone} /></p>
                <p><span className={Styles.pr_bold}>Facebook: </span> <Link to="https://www.facebook.com/theprairieridge">Prairie Ridge Church</Link></p>
            </div>
        </div>
    </div>)
}
export default ContactUs;