import React, {useEffect, useState} from "react";
import Styles from "./styles/pr.module.css";

const GenderPicker = (props) => {


    return (
        <select
            className={Styles.pr_membership_status_selector}
            value={props.gender}
            name="gender"
            onChange={(event) => props.onChange(event)}
        >
            <option value="">-</option>
            <option value={0}>Male</option>
            <option value={1}>Female</option>
        </select>
    )
}

export default GenderPicker;