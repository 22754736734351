import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const YouthNight = (props) => {

    return (<div>
        <h2>{props.page_title}</h2>
        <div className={`${Styles.pr_center} ${Styles.pr_font_18}`}>1st Wednesday of Every Month</div>
        <div className={`${Styles.pr_center} ${Styles.pr_font_14}`}>6:30pm - 8:00pm</div>
        <div className={`${Styles.pr_center} ${Styles.pr_font_14} ${Styles.pr_padding_20}`}>Free Family Dinner at 6:30pm. Everyone welcome!</div>
        <div>
            <div>
                <div className={Styles.pr_heading_medium}>Teen Group</div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>
                        We're studying through the miracles of Jesus, learning how to read the Bible on our own and
                        apply God's word to our lives. We pray together, share our joys and struggles, and engage in
                        works of service to the community.
                    </p>
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Kids</div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>
                        We engage in crafts, games, and music as we learn about Jesus, the Bible, and living for God.
                        Ages 3 and up (though younger kids are absolutely welcome with a guardian).
                    </p>
                </div>
            </div>
        </div>
    </div>)
}
export default YouthNight;