import React, {useState} from "react";
import Styles from "./styles/pr.module.css";
import {Link} from "react-router-dom";
import YouTubeLogo from "./images/youtubelogo.png";
import RumbleLogo from "./images/rumble_logo.png";
import SpotifyLogo from "./images/spotifylogo.png";

const SermonListItem = (props) => {
    const [verseLink, setVerseLink] = useState(
        "https://www.biblegateway.com/passage/?search=" + props.data.bible_text + "&version=NIV"
    );
    const extract_spotify_url_from_embed_code = (code) => {
        //code = '<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/2MSx3e2TZ2e1phvek15DIr?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>';
        let start = code.indexOf('src="') + 5;
        let end = code.indexOf('"', start + 1);
        let url = code.substring(start, end);
        return url;
    }
    return (
        <tr className={Styles.pr_sermon_table_row}>
            <td>{props.data.sermon_date}</td>
            <td>{props.data.title}</td>
            <td><Link to={verseLink} target="_blank">{props.data.bible_text}</Link></td>
            <td>{props.data.description}</td>
            <td>
                {props.data.spotify_link ? (
                    <Link to={extract_spotify_url_from_embed_code(props.data.spotify_link)}>
                        <img className={Styles.pr_vid_logos} src={SpotifyLogo} />
                    </Link>
                ) : ''}
            </td>
            <td>
            {props.data.rumble_url ? (
                <Link to={props.data.rumble_url}><img className={Styles.pr_vid_logos} src={RumbleLogo} /></Link>
            ) : ''}
            </td>
            <td>
            {props.data.youtube_url ? (
                <Link to={props.data.youtube_url}><img className={Styles.pr_vid_logos} src={YouTubeLogo} /></Link>
            ) : ''}
            </td>
        </tr>
    )
}
export default SermonListItem;