import React, {useEffect, useState, useContext} from "react";
import {load_fetch} from "./pr_utils";
import FamilyDataRow from "./FamilyDataRow";
import Styles from "./styles/pr.module.css";
import {AuthContext} from "./AuthContext";
import {Navigate} from "react-router-dom";
import AddPersonForm from "./AddPersonForm";


//TODO: Next Steps:
// -Add code to be able to upload/edit/delete images
// -Add a feature to send a weekly email to me of birthdays/anniversaries coming up in the next two weeks
// -Add login/users/security (Long term can make it so that people can edit their own data? No, but maybe email a report of your data so that you can update it)

const Directory = (props) => {
    const [familyData, setFamilyData] = useState([]);
    const [selectedFamilyId, setSelectedFamilyId] = useState(0);
    const [birthdaysAndAnniversaries, setBirthdaysAndAnniversaries] = useState([]);
    const {authStatus, setAuthStatus} = useContext(AuthContext);
    const [isEditing, setIsEditing] = useState(false);
    const [addingPerson, setAddingPerson] = useState(false);
    const [personToDelete, setPersonToDelete] = useState({id: '', name: ''});
    const [familyToDelete, setFamilyToDelete] = useState({family_id: '', family_name: ''});
    const [canEdit, setCanEdit] = useState(false);
    const [adminCreatingPassword, setAdminCreatingPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [newPasswordValid, setNewPasswordValid] = useState(false);
    const [newUserName, setNewUserName] = useState('');
    const [usernameExists, setUsernameExists] = useState(false);
    const [roles, setRoles] = useState(false);
    const [newUserRole, setNewUserRole] = useState(false);
    const [birthdayAnniversaryStartDate, setBirthdayAnniversaryStartDate] = useState(false);

    //USE EFFECTS
    useEffect(() => {
        get_families();
        get_birthdays_and_anniversaries();
        get_roles();
        if(props.user_info['role'] === 'ADMIN' || props.user_info['role'] === 'DIRECTORY_EDITOR') {
            setCanEdit(true);
        }
    }, []);
    useEffect(() => {
        live_validate_password();
    }, [newPassword, newPassword2]);

    //AJAX FETCH DATA FUNCTIONS
    const get_families = () => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'GET_FAMILIES'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setFamilyData(data);
            } else {
                console.log(data);
            }
        });
    }
    const get_birthdays_and_anniversaries = (starting_date) => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'GET_BIRTHDAYS_AND_ANNIVERSARIES_NEXT_WEEK',
            starting_date: starting_date ?? null
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setBirthdaysAndAnniversaries(data);
            } else {
                console.log(data);
            }
        });
    }
    const delete_person = () => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'DELETE_PERSON',
            person_id: personToDelete['id']
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                get_families();
                setSelectedFamilyId(0);
                setPersonToDelete({id:'', name:''});
            } else {
                console.log(data);
            }
        });
    }
    const delete_family = () => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'DELETE_FAMILY',
            family_id: familyToDelete['family_id']
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                get_families();
                setSelectedFamilyId(0);
                setFamilyToDelete({family_id:'', family_name:''});
            } else {
                console.log(data);
            }
        });
    }
    const admin_create_password = () => {
        let url = 'Security_Handler.php';
        let postData = {
            operation: 'SET_PASSWORD',
            person_id: adminCreatingPassword['person_id'],
            password: newPassword,
            username: newUserName,
            role: newUserRole
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                alert("Success. New Password Set.");
                setAdminCreatingPassword(false);
                setNewPasswordValid(false);
                setNewPassword2('');
                setNewPassword('');
                setNewUserName('');
                setNewUserRole(false);
            } else {
                alert("Failure. New Password Not Set.");
                console.log(data);
            }
        });
    }
    const get_roles = () => {
        let url = 'Security_Handler.php';
        let postData = {
            operation: 'GET_ROLES'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                console.log(data);
                setRoles(data);
            } else {
                console.log("Failed to load roles.");
                console.log(data);
            }
        });
    }
    const check_username_exists = () => {
        let url = 'Security_Handler.php';
        let postData = {
            operation: 'USERNAME_EXISTS',
            username: newUserName
        }
        load_fetch(url, postData, (data) => {
            setUsernameExists(data);
        });
    }


    //OTHER FUNCTIONS
    const toggle_selected_family = (family_id) => {
        if(isEditing) {
            return;
        }
        if(family_id == selectedFamilyId) {
            setSelectedFamilyId(0);
        } else {
            setSelectedFamilyId(family_id);
        }
    }
    const toggle_is_editing = () => {
        setIsEditing(!isEditing);
    }
    const update_family = (new_family_data) => {
        //Eventually I should handle this entirely client side, since I have the
        //data... for now, just triggering a server reload of data
        get_families();
        setAddingPerson(false);
    }
    const show_add_person_form = () => {
        toggle_is_editing();
        setAddingPerson(true);
    }
    const cancel_adding_person = () => {
        setAddingPerson(false);
        toggle_is_editing();
    }
    const delete_family_are_you_sure = (family_id, family_name) => {
        setFamilyToDelete({family_id: family_id, family_name: family_name})
    }
    const delete_person_are_you_sure = (id, real_name) => {
        setPersonToDelete({id: id, name: real_name})
    }
    const cancel_deleting_person = () => {
        setPersonToDelete({id:'', name:''});
    }
    const cancel_deleting_family = () => {
        setFamilyToDelete({family_id:'', family_name:''});
    }
    const show_admin_create_password_modal = (person_data) => {
        console.log(person_data['first_name'] + " " + person_data['last_name']);
        setAdminCreatingPassword(person_data);
    }
    const cancel_admin_creating_password = () => {
        setAdminCreatingPassword(false);
    }
    const validate_password_strength = (pword) => {
        let regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        return regularExpression.test(pword);
    }
    const live_validate_password = () => {
        let valid = validate_password_strength(newPassword);
        if(newPassword !== newPassword2) {
            setNewPasswordValid(false);
        } else {
            setNewPasswordValid(valid);
        }
    }
    const view_print_directory = () => {
        window.location.href='print_directory';
    }


    if(!authStatus) {
        return <Navigate to={'/'} />
    } else {
        return (<>
            <h2>{props.page_title}</h2>
            <div>
                <div>Get Anniversaries and Birthdays for Week Starting on:</div>
                <div><input
                    type="date"
                    value={birthdayAnniversaryStartDate}
                    name="baStartDateInput"
                    onChange={(event) => setBirthdayAnniversaryStartDate(event.target.value)}
                /> (pick the Sunday)</div>
                <div><button onClick={get_birthdays_and_anniversaries(birthdayAnniversaryStartDate)}
                             className={Styles.pr_hide}>Go</button></div>
            </div>
            <div className={Styles.birth_anniversary_div}>
                <div className={Styles.pr_bold}>Birthdays Next Week</div>
                {birthdaysAndAnniversaries['birthdays'] && (
                    <>{birthdaysAndAnniversaries['birthdays'].map((bday, index) => (
                        <div key={index}>{bday}</div>
                    ))}</>
                )}
            </div>
            <div className={Styles.birth_anniversary_div}>
                <div className={Styles.pr_bold}>Anniversaries Next Week</div>
                {birthdaysAndAnniversaries['anniversaries'] && (
                    <>{birthdaysAndAnniversaries['anniversaries'].map((bday, index) => (
                        <div key={index}>{bday}</div>
                    ))}</>
                )}
            </div>
            <div className={isEditing || addingPerson ? Styles.pr_hide : ''}>
                <button
                    className={canEdit ? Styles.pr_add_person_button : Styles.pr_hide}
                    onClick={show_add_person_form}
                >Add New Person</button>
            </div>
            <div className={addingPerson ? '' : Styles.pr_hide}>
                <AddPersonForm
                    cancel_adding_person={cancel_adding_person}
                    update_families={update_family}
                    toggle_is_editing={toggle_is_editing}
                />
            </div>
            <div>
                <button
                    className={Styles.pr_add_person_button}
                    onClick={view_print_directory}
                >View Printable Directory</button>
            </div>
            <div>
                {familyData.length > 0 && familyData.map((item, index) => (
                    <FamilyDataRow
                        is_admin={props.user_info['role'] === 'ADMIN'}
                        is_editor={props.user_info['role'] === 'DIRECTORY_EDITOR'}
                        key={index}
                        data={item}
                        show={item['family_id'] === selectedFamilyId}
                        toggle_selected_family={toggle_selected_family}
                        isEditing={isEditing}
                        toggleIsEditing={toggle_is_editing}
                        update_family={update_family}
                        delete_person_are_you_sure={delete_person_are_you_sure}
                        delete_family_are_you_sure={delete_family_are_you_sure}
                        handle_create_password={show_admin_create_password_modal}
                    />
                ))}
            </div>

            {/* DELETE PERSON ARE YOU SURE MODAL */}
            <div
                className={personToDelete['id'] !== '' ? Styles.pr_modal_visible : Styles.pr_modal}
                id='delete_person_modal'
            >
                <div className={Styles.pr_modal_content}>
                    <div className={Styles.pr_modal_header}>
                        ⚠️WARNING! DELETING A PERSON! ⚠️
                    </div>
                    <div className={Styles.pr_modal_body}>
                        Are you sure you want to delete the person: {personToDelete['name']}?
                    </div>
                    <div className={Styles.pr_modal_footer}>
                        <button className={Styles.pr_button} onClick={delete_person}>Delete</button>
                        <button className={Styles.pr_button} onClick={cancel_deleting_person}>Cancel</button>
                    </div>
                </div>
            </div>

            {/* DELETE FAMILY ARE YOU SURE MODAL */}
            <div
                className={familyToDelete['family_id'] !== '' ? Styles.pr_modal_visible : Styles.pr_modal}
                id='delete_family_modal'
            >
                <div className={Styles.pr_modal_content}>
                    <div className={Styles.pr_modal_header}>
                        ⚠️WARNING! DELETING A FAMILY! ⚠️
                    </div>
                    <div className={Styles.pr_modal_body}>
                        Are you sure you want to delete the family: {familyToDelete['family_name']}?<br />
                        This will delete all family members as well.
                    </div>
                    <div className={Styles.pr_modal_footer}>
                        <button className={Styles.pr_button} onClick={delete_family}>Delete</button>
                        <button className={Styles.pr_button} onClick={cancel_deleting_family}>Cancel</button>
                    </div>
                </div>
            </div>

            {/* ADMIN - CREATE PASSWORD MODAL */}
            {/* DELETE FAMILY ARE YOU SURE MODAL */}
            <div
                className={adminCreatingPassword ? Styles.pr_modal_visible : Styles.pr_modal}
                id='admin_create_password_modal'
            >
                <div className={Styles.pr_modal_content}>
                    <div className={Styles.pr_modal_header}>
                        ⚠️WARNING! CREATING A NEW PASSWORD! ⚠️
                    </div>
                    <div className={Styles.pr_modal_body}>
                        <form autoComplete="off">
                            <div>This will allow the user to login to the website.</div>
                            <div>Username (or email)</div>
                            <div>
                                <input type="text" value={newUserName} placeholder="username"
                                    name="new_user_name"
                                    onChange={(event) =>
                                        setNewUserName(event.target.value)}
                                    onBlur={check_username_exists}
                                />
                                <span className={usernameExists ? Styles.pr_red : Styles.pr_hide}>Username taken</span>
                            </div>
                            <div>Password must be 6-16 characters and contain at least a letter, a number, and a special character.</div>
                            <div><input type='password' value={newPassword} placeholder="new password"
                                        name="new_password_1"
                                        onChange={(event) =>
                                            setNewPassword(event.target.value)} /></div>
                            <div><input type='password' value={newPassword2} placeholder="re-enter new password"
                                        name="new_password_2"
                                        onChange={(event) =>
                                            setNewPassword2(event.target.value)} /></div>
                            <div className={newPasswordValid ? Styles.pr_hide : Styles.pr_login_error}>
                                Password does not meet minimum requirements, or passwords do not match.
                            </div>
                            <div>
                                Role:&nbsp;
                                <select name="new_user_role" value={newUserRole}
                                        onChange={(event) =>
                                            setNewUserRole(event.target.value)}>
                                    {roles.length > 0 && roles.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className={Styles.pr_modal_footer}>
                        <button className={Styles.pr_button} onClick={admin_create_password} disabled={!newPasswordValid || usernameExists}>Create</button>
                        <button className={Styles.pr_button} onClick={cancel_admin_creating_password}>Cancel</button>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default Directory;