import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";
import HeartlandLogo from "./images/heartlandaoc.jpg";

const Heartland = (props) => {

    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>Prairie Ridge Church is a member of the Heartland Association of Churches.</p>

                    <p>It is not another denomination, but an association of like-minded churches. We share a common
                    set of beliefs (see our Statement of Faith) and we work together in ministry and encouragement of
                        one another. Also, the Association credentials the pastors of our various churches.</p>

                    <p>Click on the logo if you’d like more more information about the Heartland Association of
                        Churches.</p>

                    <div className={Styles.pr_center}><Link to="https://www.heartlandaoc.com"><img src={HeartlandLogo} /></Link></div>

                    <p>If you are an independent church that agrees with our Statement of Faith that would like to be
                    part of an association that provides support, fellowship, encouragement, and credentialing, feel
                        free to contact Curtis Romang at c.romang@yahoo.com for more information.</p>
                </div>
            </div>
        </div>
    </div>)
}
export default Heartland;