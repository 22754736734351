import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const History = (props) => {

    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div>
                <div className={Styles.pr_spacing_one_and_a_half}>
                    Prairie Ridge Christian Union Church was organized in the Old Prairie Ridge Schoolhouse on
                    December 2, 1876, by Brothers Andrew Arnote and J.M. Harder, with 16 charter members.
                    Brother George Mitchell became pastor in 1882, and plans were beginning to take shape to
                    build a house of worship. Land for the church site was donated by William Arnote. The building
                    was completed in April 1885. This Church was a frame building and stood on Section 23. On the
                    second Sunday in June 1886, the new Prairie Ridge Christian Union Church building was dedicated
                    by Rev. J.V.D. Flack.
                </div>
            </div>
        </div>
    </div>)
}
export default History;