import React, { useState } from 'react';
import { load_fetch } from './pr_utils'; // Adjust the import path as needed
import Styles from "./styles/pr.module.css";

const SermonForm = (props) => {
    // useState hooks for managing form data
    const [title, setTitle] = useState('');
    const [bibleText, setBibleText] = useState('');
    const [description, setDescription] = useState('');
    const [sermonDate, setSermonDate] = useState('');
    const [spotifyLink, setSpotifyLink] = useState('');
    const [rumbleUrl, setRumbleUrl] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');

    // Handle form submission
    const handle_submit = (event) => {
        event.preventDefault();

        // Validate required fields
        if (!title || !bibleText || !description || !sermonDate) {
            alert('Please fill out all required fields.');
            return;
        }

        const postData = {
            operation:"INSERT_SERMON",
            title:title,
            bible_text: bibleText,
            description:description,
            sermon_date: sermonDate,
            spotify_link: spotifyLink,
            rumble_url: rumbleUrl,
            youtube_url: youtubeUrl,
        };

        load_fetch('Sermon_Handler.php', postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //Call Parent reload page function
                props.reload_sermons();
                reset_form();
            } else {
                console.log(data);
            }
        });
    };

    //Reset the form
    const reset_form = () => {
        setTitle('');
        setBibleText('');
        setDescription('');
        setSermonDate('');
        setSpotifyLink('');
        setRumbleUrl('');
        setYoutubeUrl('');
    };

    return (
        <form className={Styles.pr_insert_sermon_form} onSubmit={handle_submit}>
            <div className={Styles.pr_form_group}>
                <label className={Styles.pr_insert_sermon_label}>Sermon Title:</label>
                <input className={Styles.pr_sermon_input} type="text" value={title}
                       onChange={(e) => setTitle(e.target.value)} required/>
            </div>
            <div className={Styles.pr_form_group}>
                <label className={Styles.pr_insert_sermon_label}>Bible Passage(s):</label>
                <input className={Styles.pr_sermon_input} type="text" value={bibleText}
                       onChange={(e) => setBibleText(e.target.value)} required/>
            </div>
            <div className={Styles.pr_form_group}>
                <label className={Styles.pr_insert_sermon_label}>Description:</label>
                <textarea className={Styles.pr_sermon_textarea} value={description}
                          onChange={(e) => setDescription(e.target.value)} required/>
            </div>
            <div className={Styles.pr_form_group}>
                <label className={Styles.pr_insert_sermon_label}>Sermon Date:</label>
                <input className={Styles.pr_sermon_date_input} type="date" value={sermonDate}
                       onChange={(e) => setSermonDate(e.target.value)} required/>
            </div>
            <div className={Styles.pr_form_group}>
                <label className={Styles.pr_insert_sermon_label}>Spotify Embed URL:</label>
                <input className={Styles.pr_sermon_input} type="text" value={spotifyLink}
                       onChange={(e) => setSpotifyLink(e.target.value)}/>
            </div>
            <div className={Styles.pr_form_group}>
                <label className={Styles.pr_insert_sermon_label}>Rumble Embed URL:</label>
                <input className={Styles.pr_sermon_input} type="text" value={rumbleUrl}
                       onChange={(e) => setRumbleUrl(e.target.value)}/>
            </div>
            <div className={Styles.pr_form_group}>
                <label className={Styles.pr_insert_sermon_label}>YouTube Embed URL:</label>
                <input className={Styles.pr_sermon_input} type="text" value={youtubeUrl}
                       onChange={(e) => setYoutubeUrl(e.target.value)}/>
            </div>
            <button className={Styles.pr_sermon_button} type="submit">Submit Sermon</button>
        </form>

    );
};

export default SermonForm;
