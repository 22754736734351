import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const VisitUs = () => {

    return (<div>
        <div>
            <div className={Styles.pr_heading_medium}>Our Address</div>
            <div className={Styles.pr_block_quote}>
                6951 SW Hwy 116<br/>
                Polo, MO 64671<br/>

                <p>(About 5 miles west of Polo on Highway 116)</p>
            </div>
            <div>
                <iframe width="100%" height="500" id="gmap_canvas"
                        src="https://maps.google.com/maps?q=6951%20SW%20HWY.%20116%20%20Polo%2C%20MO%2064671&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
            </div>
        </div>
    </div>)
}
export default VisitUs;