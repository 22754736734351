import React, {useState} from "react";
import Styles from "./styles/pr.module.css";
import {Link} from "react-router-dom";

const OneSermonView = (props) => {
    const [verseLink, setVerseLink] = useState(
        "https://www.biblegateway.com/passage/?search=" + props.data.bible_text + "&version=NIV"
    );
    return (
        <div className={Styles.pr_sermon_block}>
            <div className={Styles.pr_heading_medium}>{props.data.title}</div>
            <div className={`${Styles.pr_block_quote} ${Styles.pr_font_146}`}><Link to={verseLink} target="_blank">{props.data.bible_text}</Link></div>
            <div className={Styles.pr_embed_video_holder}>
                {props.data.spotify_link ? (
                    <div dangerouslySetInnerHTML={{__html: props.data.spotify_link}}></div>
                ) : (
                    <iframe className={Styles.pr_video} src={props.data.rumble_url} />
                )}
            </div>
            <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                {props.data.description}
            </div>
            <div className={`${Styles.pr_block_quote} ${Styles.pr_font_16}`}>
                {props.data.youtube_url ? <p>Watch instead on <Link to={props.data.youtube_url}>YouTube</Link></p> : ''}
            </div>
        </div>
    )
}
export default OneSermonView;