import React, {useEffect, useState, useContext} from "react";
import {load_fetch} from "./pr_utils";
import Styles from "./styles/pr.module.css";
import {AuthContext} from "./AuthContext";
import * as PropTypes from "prop-types";
import {Navigate} from "react-router-dom";


const PrintDirectory = (props) => {
    const [data, setData] = useState('');

    //USE EFFECTS
    useEffect(() => {
        if(props.can_view_directory) {
            get_directory();
        }
    }, [props.can_view_directory])

    //AJAX FUNCTIONS
    const get_directory = () => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'GET_PRINT_DIRECTORY'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setData(data);
                console.log(data);
            } else {
                console.log("Failed to load directory.");
                console.log(data);
            }
        });
    }
    if(!props.can_view_directory) {
        return <div>Not logged in or no permissions.</div>
    } else {
        return (
            data && data.map((family, index) => (
                <div className={Styles.pr_print_family} key={index}>
                    <div className={Styles.pr_print_family_name}>{family['family_name']}</div>
                    <img
                        src={`./profile_images/family/${family['family_id']}.jpg`}
                        width={`${family['photo_width']}px;`}
                        className={Styles.pr_print_photo}
                    />
                    <div className={Styles.pr_print_info_div}>
                        <div className={Styles.pr_print_heads_of_household}>
                            <div className={Styles.pr_print_head_of_household}>
                                <div>
                                    {family['head_of_household']['full_name']}
                                </div>
                                <div className={Styles.pr_print_subinfo}>
                                    {family['head_of_household']['birthday']}
                                    <span className={Styles.pr_print_data_note}>
                                &nbsp;born
                            </span>
                                </div>
                                <div className={Styles.pr_print_subinfo}>
                                    {family['head_of_household']['cell']}
                                    <span className={Styles.pr_print_data_note}>
                                &nbsp;cell
                            </span>
                                </div>
                                <div className={Styles.pr_print_subinfo}>
                                    {family['head_of_household']['email']}
                                </div>
                            </div>
                            <div className={Styles.pr_print_spouse}>
                                <div>
                                    {family['spouse']['full_name']}
                                </div>
                                <div className={Styles.pr_print_subinfo}>
                                    {family['spouse']['birthday']}
                                    <span className={Styles.pr_print_data_note}>
                                &nbsp;born
                            </span>
                                </div>
                                <div className={Styles.pr_print_subinfo}>
                                    {family['spouse']['cell']}
                                    <span className={Styles.pr_print_data_note}>
                                &nbsp;cell
                            </span>
                                </div>
                                <div className={Styles.pr_print_subinfo}>
                                    {family['spouse']['email']}
                                </div>
                            </div>
                        </div>
                        <div className={Styles.pr_print_fam_and_children_row}>
                            <div className={Styles.pr_print_family_info}>
                                <div className={Styles.pr_print_address}>
                                    <div className={Styles.pr_print_fam_info_item}>
                                        {family['address_1']}
                                    </div>
                                    <div className={Styles.pr_print_fam_info_item}>
                                        {family['address_2']}
                                    </div>
                                    <div className={Styles.pr_print_fam_info_item}>
                                        {family['city']}, {family['state']} {family['zip']}
                                    </div>
                                </div>
                                <div className={Styles.pr_print_extra_family_info}>
                                    <div className={Styles.pr_print_fam_info_item}>
                                        {family['family_home_phone']}
                                    </div>
                                    <div className={Styles.pr_print_fam_info_item}>
                                        {family['family_email']}
                                    </div>
                                    <div className={Styles.pr_print_fam_info_item}>
                                        {family['anniversary']} <span
                                        className={Styles.pr_print_data_note}> anniversary</span>
                                    </div>
                                </div>
                            </div>
                            <div className={Styles.pr_print_children}>
                                {family['children'] && family['children'].map((kid, kid_index) => (
                                    <div key={kid_index}>{kid}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )))
    }
}



export default PrintDirectory;