import React, {useEffect, useState} from "react";
import Styles from "./styles/pr.module.css";
import {date_to_ordinal, load_fetch, number_to_month_name} from "./pr_utils";
import MembershipStatus from "./MembershipStatus";
import GenderPicker from "./GenderPicker";

const EditPerson = (props) => {
    const [personData, setPersonData] = useState({
        person_id: props.data.person_id,
        title: props.data.title ?? '',
        first_name: props.data.first_name,
        middle_name: props.data.middle_name ?? '',
        last_name: props.data.last_name,
        suffix: props.data.suffix ?? '',
        membership_status: props.data.membership_status,
        cell_phone: props.data.cell_phone ?? '',
        email: props.data.email ?? '',
        birth_month: props.data.birth_month ?? '',
        birth_date: props.data.birth_date ?? '',
        birth_year: props.data.birth_year ?? '',
        gender: props.data.gender,
        membership_date: props.data.membership_date ?? '',
        family_id: props.data.family_id,
        created_date: props.data.created_date,
        created_by: props.data.created_by,
        last_edited_date: props.data.last_edited_date ?? '',
        last_edited_by: props.data.last_edited_by ?? '',
        head_of_household: props.data.head_of_household ?? '',
        password_hash: props.data.password_hash ?? '',
        last_login_attempt: props.data.last_login_attempt ?? '',
        directory_include_email: props.data.directory_include_email ?? '',
    });

    //AJAX FETCH DATA FUNCTIONS
    const save_data = () => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'UPDATE_PERSON_DATA',
            data: personData
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                props.update_person_data_after_save(data);
            } else {
                console.log("ERROR UPDATING PERSON");
                console.log(data);
            }
        });
    }

    //OTHER FUNCTIONS
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPersonData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const handle_checkbox = (event) => {
        setPersonData(prevData => ({
            ...prevData,
            [event.target.name]: event.target.checked ? 1 : 0
        }));
    }

    //RENDERING
    return (
        <div className={Styles.pr_edit_person_div}>
            <div>
                <label>
                    Title:
                    <input
                        type="text"
                        name="title"
                        value={personData.title}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    First Name:
                    <input
                        type="text"
                        name="first_name"
                        value={personData.first_name}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Middle Name:
                    <input
                        type="text"
                        name="middle_name"
                        value={personData.middle_name}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Last Name:
                    <input
                        type="text"
                        name="last_name"
                        value={personData.last_name}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Suffix:
                    <input
                        type="text"
                        name="suffix"
                        value={personData.suffix}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Membership Status:
                    <MembershipStatus
                        value={personData.membership_status}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Cell Phone:
                    <input
                        type="text"
                        name="cell_phone"
                        value={personData.cell_phone}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Email:
                    <input
                        type="text"
                        name="email"
                        value={personData.email}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Birth Month:
                    <input
                        type="text"
                        name="birth_month"
                        value={personData.birth_month}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Birth Date:
                    <input
                        type="text"
                        name="birth_date"
                        value={personData.birth_date}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Birth Year:
                    <input
                        type="text"
                        name="birth_year"
                        value={personData.birth_year}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Gender:
                    <GenderPicker
                        gender={personData.gender}
                        onChange={handleInputChange}/>
                </label>
            </div>

            <div>
                <label>
                    Membership Date:
                    <input
                        type="date"
                        name="membership_date"
                        value={personData.membership_date}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Head of Household:
                    <input
                        type="checkbox"
                        className={Styles.pr_checkbox}
                        name="head_of_household"
                        checked={personData.head_of_household ? true : false}
                        onChange={(event) => handle_checkbox(event)}
                    />
                </label>
            </div>

            <div>
                <label>
                    Directory Include Email:
                    <input
                        className={Styles.pr_checkbox}
                        type="checkbox"
                        name="directory_include_email"
                        checked={personData.directory_include_email ? true : false}
                        onChange={(event) => handle_checkbox(event)}
                    />
                </label>
            </div>

            <div className={Styles.pr_edit_person_button_row}>
                <button
                    className={Styles.pr_directory_edit_button}
                    onClick={(event) => save_data(event)}
                >SAVE</button>
                <button
                    className={Styles.pr_directory_cancel_button}
                    onClick={(event) => props.cancel_editing(event)}
                >CANCEL</button>
            </div>



        </div>
    );
}
export default EditPerson;