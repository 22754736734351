import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const ChurchLeaders = (props) => {

    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div>
                <div className={Styles.pr_heading_medium}>Mike Turner, Pastor</div>
                <div className={Styles.pr_block_quote}>
                    About Mike Turner
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Jeff Gentry, Elder</div>
                <div className={Styles.pr_block_quote}>
                    About Jeff
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Gina Peoples, Elder</div>
                <div className={Styles.pr_block_quote}>
                    About Gina
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Janet Stonum, Elder</div>
                <div className={Styles.pr_block_quote}>
                    About Janet
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Allen Heinz, Elder</div>
                <div className={Styles.pr_block_quote}>
                    About Allen
                </div>
            </div>
        </div>
    </div>)
}
export default ChurchLeaders;