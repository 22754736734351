import React, {useEffect, useState} from "react";
import Styles from "./styles/pr.module.css";
import {load_fetch} from "./pr_utils";

const MembershipStatus = (props) => {
    const [data, setData] = useState(false);

    //USE EFFECTS
    useEffect(() => {
        if(!data) {
            get_membership_status_data();
        }
    }, []);

    //AJAX FETCH DATA FUNCTIONS
    const get_membership_status_data = () => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'GET_MEMBERSHIP_STATUSES'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setData(data);
            } else {
                console.log(data);
            }
        });
    }
    return (
        <select
            className={Styles.pr_membership_status_selector}
            name="membership_status"
            onChange={(event) => props.onChange(event)}
            value={props.value}
        >
            <option value="">-</option>
            {data && data.map((status, index) => (
                <option
                    key={index}
                    value={status['membership_constant']}
                >
                    {status['name']}
                </option>
            ))}
        </select>
    )
}

export default MembershipStatus;