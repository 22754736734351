import React, {useEffect, useState} from "react";
import Styles from "./styles/pr.module.css";
import {date_to_ordinal, load_fetch, number_to_month_name} from "./pr_utils";
import EditPerson from "./EditPerson";
import EditFamily from "./EditFamily";

const FamilyDataRow = (props) => {
    //STATE VARIABLES
    const [familyData, setFamilyData] = useState([]);
    const [editingFamily, setEditingFamily] = useState(false);
    const [editingPerson, setEditingPerson] = useState(false);
    const [editingPersonId, setEditingPersonId] = useState(0);

    //USE EFFECT HOOKS
    useEffect(() => {
        if(props.show) {
            get_family_data();
        } else {
            setFamilyData([]);
        }
    }, [props.show]);

    //AJAX FETCH DATA FUNCTIONS
    const get_family_data = () => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'GET_SELECTED_FAMILY_DATA',
            family_id: props.data['family_id']
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setFamilyData(data);
            } else {
                console.log(data);
            }
        });
    }


    //CONTROL FUNCTIONS
    const start_editing = (event) => {
        event.stopPropagation();
        setEditingFamily(true);
        props.toggleIsEditing();
    }
    const start_editing_person = (event, person_id) => {
        setEditingPersonId(person_id);
        event.stopPropagation();
        setEditingPerson(true);
        props.toggleIsEditing();
    }
    const cancel_editing_person = () => {
        setEditingPerson(false);
        setEditingPersonId(0);
        props.toggleIsEditing();
    }
    const update_family_data_after_save = (new_family_data) => {
        setFamilyData(prevData => ({
            ...new_family_data
        }));
        props.update_family(new_family_data);
        cancel_edits();
    }
    const update_person_data_after_save = (new_person_data) => {
        //Loop through familyData['people']
        let localFamilyData = JSON.parse(JSON.stringify(familyData));
        for(let i = 0; i < localFamilyData['people'].length; i++) {
            if(localFamilyData['people'][i]['person_id'] === new_person_data[0]['person_id']) {
                localFamilyData['people'][i] = new_person_data[0];
            }
        }
        setFamilyData(prevData => ({...localFamilyData}));
        cancel_editing_person();
    }
    const cancel_edits = () => {
        props.toggleIsEditing();
        setEditingFamily(false);
    }
    const delete_person_are_you_sure = (event, id, name) => {
        event.stopPropagation();
        props.delete_person_are_you_sure(id, name);
    }
    const delete_family_are_you_sure = (event, family_id, family_name) => {
        event.stopPropagation();
        props.delete_family_are_you_sure(family_id, family_name);
    }
    const create_password = (event, person_data) => {
        event.stopPropagation();
        props.handle_create_password(person_data);
    }



    return (
        <div
            className={Styles.pr_family_row}
            onClick={() => props.toggle_selected_family(props.data.family_id)}
        >
            {editingFamily ? <EditFamily
                    data={props.data}
                    toggleIsEditing={props.toggleIsEditing}
                    cancel_edits={cancel_edits}
                    update_family_data_after_save={update_family_data_after_save}
                /> :
                <div className={Styles.pr_family_data_row}>
                <div className={Styles.pr_family_name}>
                    {props.data.family_name}
                </div>
                <div className={Styles.pr_address_div}>
                    <div>{props.data.address_1}</div>
                    <div>{props.data.address_2}</div>
                    <div>{props.data.city}{props.data.city && props.data.state ? ',' : ''} {props.data.state} {props.data.zip}</div>
                </div>
                <div className={Styles.pr_more_info_div}>
                    <div>Home Phone: {props.data.home_phone}</div>
                    <div>Anniversary: {props.data.wedding_month && props.data.wedding_date ? `${number_to_month_name(props.data.wedding_month)} ${date_to_ordinal(props.data.wedding_date, '')}` : ''}</div>
                </div>
                </div>
            }
            <div> {/* EDIT FAMILY AND DELETE FAMILY BUTTONS */}
                {editingFamily || props.isEditing || editingPerson || (!props.is_admin && !props.is_editor) ? '' :
                    <>
                    <button
                        className={props.show ? Styles.pr_directory_delete_button : Styles.pr_hide}
                        onClick={(event) =>
                            delete_family_are_you_sure(event, props.data['family_id'], props.data['family_name'])}
                    >DELETE</button>
                    <button
                        className={props.show ? Styles.pr_directory_edit_button : Styles.pr_hide}
                        onClick={(event) => start_editing(event)}
                    >EDIT</button>
                    </>
                }
            </div>
            <div className={props.show ? Styles.pr_hidden_details_row : Styles.pr_hide}>
                {familyData[0] && (
                    <>
                        <div>
                        {props.data['family_image'] && (
                            <img className={Styles.pr_family_profile_pic} src={props.data['family_image']} />
                        )}
                        </div>
                        <div>
                            {familyData['people'].map((person, index) => (
                                <div key={index} className={Styles.pr_person_basic_row}>
                                    {editingPersonId === person['person_id'] ?
                                    <EditPerson
                                        data={person}
                                        cancel_editing={cancel_editing_person}
                                        update_person_data_after_save={update_person_data_after_save}
                                    /> :
                                        <>
                                        <div>
                                            <b>{person['title'] ? person['title'] + ' ' : ''} {person['first_name']}
                                                {' '}{person['middle_name']} {person['last_name']}</b>{person['age'] ?
                                                ' - ' + person['age'] : ''} {editingFamily ||
                                                    props.isEditing || editingPerson ? '' :
                                            <>
                                            <button className={props.is_editor || props.is_admin ? '' : Styles.pr_hide}
                                                    onClick={(event) =>
                                                start_editing_person(event, person['person_id'])}>edit</button>
                                            <button className={props.is_editor || props.is_admin ? '' : Styles.pr_hide}
                                                     onClick={(event) =>
                                                delete_person_are_you_sure(event, person['person_id'],
                                                    person['first_name'] + ' ' + person['last_name'])}>delete</button>
                                            <button
                                                className={props.is_admin ? '' : Styles.pr_hide}
                                                onClick={(event) =>
                                                create_password(event, person)}>Create Login Account</button>
                                            </>
                                            }
                                        </div>
                                        <div>{person['birthdate'] ? 'Birthdate: ' + person['birthdate'] : ''}</div>
                                        <div>Cell: {person['cell_phone']}</div>
                                        <div>Gender: {person['gender'] ? 'F' : 'M'}</div>
                                        <div>Email: {person['email']}</div>
                                        <div>Membership Status: {person['membership_status']}</div>
                                        <div>Membership Date: {person['membership_date']}</div>
                                        <div>Head of Household: {person['head_of_household'] ? "YES" : "NO"}</div>
                                        </>
                                    }
                                </div>
                            ))}
                        </div>

                    </>
                )}
            </div>
        </div>
    )
}
export default FamilyDataRow;
