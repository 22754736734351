import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const RanaesBaskets = (props) => {

    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div>
                <div>
                    <p>Christmas baskets will be filled again this year to give to needy families.
                    The number of baskets to be filled depends on the amount of contributions received.
                    Please feel free to bring these items any time during the collection period.  We will be planning
                        a mid-December delivery date.  Items can be left in the foyer.</p>

                    <p>We’ll be collecting during the month of October.</p>
                </div>
                <div className={Styles.pr_block_quote}>
                    <div>Suggested Items to Bring</div>
                    <ul>
                        <li>Paper Products (TP, Paper Towels, Kleenex)</li>
                        <li>Laundry Soap, Fabric Softener, and other cleaners</li>
                        <li>Toothbrushes, Toothpaste, Mouthwash, and Dental Floss</li>
                        <li>Canned Goods and Boxed Dinners</li>
                        <li>$10 Gift Cards (WalMart, Target, and Grocers)</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>)
}
export default RanaesBaskets;