import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const StatementOfFaith = (props) => {

    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div>
                <div className={Styles.pr_heading_medium}>God</div>
                <div className={Styles.pr_block_quote}>We believe that there is one God, who forever exists as three persons: God the Father, God the Son (Jesus), and God the Holy Spirit.</div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Jesus</div>
                <div className={Styles.pr_block_quote}>
                    <div>We believe that Jesus Christ...</div>
                    <ul>
                        <li>is truly God,</li>
                        <li>is our Lord and King,</li>
                        <li>became a true human being,</li>
                        <li>was born of a virgin,</li>
                        <li>lived a sinless life,</li>
                        <li>performed miracles,</li>
                        <li>died on the cross,</li>
                        <li>was bodily raised from the dead,</li>
                        <li>rose up into heaven and sat down at the right hand of the Father, and</li>
                        <li>will bodily return to earth in power and glory.</li>
                    </ul>
                </div>
            </div>

            <div>
                <div className={Styles.pr_heading_medium}>The Holy Spirit</div>
                <div className={Styles.pr_block_quote}>We believe that the Holy Spirit lives today in Christian believers and enables them to live godly lives.</div>
            </div>

            <div>
                <div className={Styles.pr_heading_medium}>Salvation</div>
                <div className={Styles.pr_block_quote}>We believe that Jesus Christ lived a sinless life and died on the cross in our place to take the punishment that we deserved for our sins.</div>
                <div className={Styles.pr_block_quote}>We believe that salvation can only come as the free gift of God. It cannot be earned. It is received only by faith placed only in Christ, and not by our good deeds. </div>
                <div className={Styles.pr_block_quote}>When a person receives Jesus by faith, the Holy Spirit gives them new life and a new spirit, and they are born again.</div>
            </div>

            <div>
                <div className={Styles.pr_heading_medium}>The Church</div>
                <div className={Styles.pr_block_quote}>We believe that all believers in the Lord Jesus Christ are joined together in the one universal church.</div>
            </div>

            <div>
                <div className={Styles.pr_heading_medium}>The Bible</div>
                <div className={Styles.pr_block_quote}>We believe that the Bible is the only true word of God, that it is free from error, and that it has authority over our lives.</div>
            </div>

            <div>
                <div className={Styles.pr_heading_medium}>The Future</div>
                <div className={Styles.pr_block_quote}>We believe that one day everyone will be bodily raised from the dead, both believers in Jesus and unbelievers. Believers will live eternally with the Lord. The lost will live an eternity of punishment and separation from God.</div>
                <div className={Styles.pr_block_quote}>We believe that Jesus could return at any time and that Christians are expected to always be ready. This is both our hope and an encouragement to live holy lives and to serve the Lord faithfully.</div>
            </div>

        </div>
    </div>)
}
export default StatementOfFaith;