import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";
import NewHopeLogo from "./images/newhopelogo.jpg";

const NewHopeMissions = () => {

    return (<div>
        <img src={NewHopeLogo} />
        <div>
            <div className={Styles.pr_heading_medium}>What NHMI Does</div>
            <div className={Styles.pr_block_quote}>New Hope Missions International is a partnership ministry between
                local (to Haiti) and international churches. NHMI sponsors individual children, provides food,
                engages in discipleship and vocational training, plants churches, and operates schools in northern Haiti.
            </div>
            <div className={Styles.pr_heading_medium}>Get Involved?</div>
            <div className={Styles.pr_block_quote}>Contact Prairie Ridge Church or contact NHMI directly:&nbsp;
                <Link to="https://newhopehaiti.com">New Hope Missions International</Link>
            </div>
        </div>
    </div>)
}
export default NewHopeMissions;