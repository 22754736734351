import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const AboutUs = () => {

    return (<div>
        <h2>About Us</h2>
        <div>
            <div className={Styles.pr_heading_medium}>Who We Are:</div>
            <div className={Styles.pr_block_quote}>Prairie Ridge Church is a non-denominational Christian church.</div>

            <div className={Styles.pr_heading_medium}>Our Beliefs</div>
            <div className={Styles.pr_block_quote}>Our <Link to="../statement_of_faith">Statement of Faith</Link> will tell you the essentials of what we believe.</div>

            <div className={Styles.pr_heading_medium}>Our Mission</div>
            <div className={Styles.pr_block_quote}>Our desire is to build up the church in love and knowledge of God and to equip one another to make disciples in the world.</div>

            <div className={Styles.pr_heading_medium}>Our Worship Services</div>
            <div className={Styles.pr_block_quote}>
                <p>In our church services, we aim to worship the Lord through:</p>
                <p>Prayer, Praise, and Proclamation of the Word of God</p>
            </div>
        </div>
    </div>)
}
export default AboutUs;