import React from "react";
import {Link} from "react-router-dom";

const Calendar = (props) => {


    return (<>
        <div>
            <iframe
                src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FChicago&src=NTI2OTc1NTdkMmRiNDQxMTQyNzczN2Q0ZjNlZjMzNjMxNDQ1YmJlZTc3MTRlZDhmZTUxMzg4ZjY3ZWM3OGNjYkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23D81B60"
                style={{border: 'solid 1px #777'}}
                width="800"
                height="600"
                frameBorder="0"
                scrolling="no"
            />
        </div>
    </>)
}

export default Calendar;