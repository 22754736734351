import Styles from "./styles/pr.module.css";
import React, {useEffect, useState} from "react";
import MembershipStatus from "./MembershipStatus";
import GenderPicker from "./GenderPicker";
import {load_fetch} from "./pr_utils";

const AddPersonForm = (props) => {
    //DATA
    const [families, setFamilies] = useState([]);
    const [creatingFamily, setCreatingFamily] = useState(false);
    const [personData, setPersonData] = useState({
        person_id: '',
        title: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix: '',
        membership_status: '',
        cell_phone: '',
        email: '',
        birth_month: '',
        birth_date: '',
        birth_year: '',
        gender: '',
        membership_date: '',
        family_id: '',
        created_date: '',
        created_by: '',
        last_edited_date: '',
        last_edited_by: '',
        head_of_household: '',
        password_hash: '',
        last_login_attempt: '',
        directory_include_email: '',
        family_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        home_phone: '',
        wedding_year: '',
        wedding_month: '',
        wedding_date: ''
    });

    //USEEFFECTS
    useEffect(() => {
        get_families();
    }, []);

    //AJAX FUNCTIONS
    const get_families = () => {
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'GET_FAMILY_LIST'
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setFamilies(data);
            } else {
                console.log("ERROR LOADING FAMILY LIST");
                console.log(data);
            }
        });
    }
    const save_new_person_data = () => {
        console.log(personData);
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'INSERT_NEW_PERSON',
            data: personData
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                //UPDATE THE GUI
                props.update_families(data);
                //Clear the form
                clear_forms();
                props.toggle_is_editing();
            } else {
                console.log("ERROR ADDING NEW PERSON");
                console.log(data);
            }
        });
    }

    //OTHER FUNCTIONS
    const handle_text_input_change = (event) => {
        const { name, value } = event.target;
        setPersonData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const handle_checkbox_change = (event) => {
        setPersonData(prevData => ({
            ...prevData,
            [event.target.name]: event.target.checked ? 1 : 0
        }));
    }
    const start_creating_family = () => {
        //Clear the Family Selector
        setPersonData(prevData => ({
            ...prevData,
            family_id: ''
        }));

        //Start or Cancel Creating a New Family
        setCreatingFamily(!creatingFamily);
    }
    const cancel_creating_family = () => {
        setCreatingFamily(false);
    }
    const clear_forms = () => {
        setPersonData({
            person_id: '',
            title: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            membership_status: '',
            cell_phone: '',
            email: '',
            birth_month: '',
            birth_date: '',
            birth_year: '',
            gender: '',
            membership_date: '',
            family_id: '',
            created_date: '',
            created_by: '',
            last_edited_date: '',
            last_edited_by: '',
            head_of_household: '',
            password_hash: '',
            last_login_attempt: '',
            directory_include_email: '',
            family_name: '',
            address_1: '',
            address_2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            home_phone: '',
            wedding_year: '',
            wedding_month: '',
            wedding_date: ''
        });
        setCreatingFamily(false);
    }

    //TODO:
    // Add deletion of families
    // Add deletion of images when a person or family is deleted
    // Add uploading of images
    // Prevent the adding of a new person without choosing a family

    //RENDERING
    return (
        <div className={Styles.pr_add_person_form}>
            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Title
                </div>
                <div>
                    <input
                        type="text"
                        name="title"
                        onChange={handle_text_input_change}
                        value={personData['title']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    First Name
                </div>
                <div>
                    <input
                        type="text"
                        name="first_name"
                        onChange={handle_text_input_change}
                        value={personData['first_name']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Middle Name
                </div>
                <div>
                    <input
                        type="text"
                        name="middle_name"
                        onChange={handle_text_input_change}
                        value={personData['middle_name']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Last Name
                </div>
                <div>
                    <input
                        type="text"
                        name="last_name"
                        onChange={handle_text_input_change}
                        value={personData['last_name']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Suffix
                </div>
                <div>
                    <input
                        type="text"
                        name="suffix"
                        onChange={handle_text_input_change}
                        value={personData['suffix']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Membership Status
                </div>
                <div>
                    <MembershipStatus
                        value={personData['membership_status']}
                        name="membership_status"
                        onChange={handle_text_input_change}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Cell Phone
                </div>
                <div>
                    <input
                        type="text"
                        name="cell_phone"
                        onChange={handle_text_input_change}
                        value={personData['cell_phone']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Email
                </div>
                <div>
                    <input
                        type="text"
                        name="email"
                        onChange={handle_text_input_change}
                        value={personData['email']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Birth Month
                </div>
                <div>
                    <input
                        type="text"
                        name="birth_month"
                        onChange={handle_text_input_change}
                        value={personData['birth_month']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Birth Date
                </div>
                <div>
                    <input
                        type="text"
                        name="birth_date"
                        onChange={handle_text_input_change}
                        value={personData['birth_date']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Birth Year
                </div>
                <div>
                    <input
                        type="text"
                        name="birth_year"
                        onChange={handle_text_input_change}
                        value={personData['birth_year']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Gender
                </div>
                <div>
                    <GenderPicker
                        gender={personData.gender}
                        name="gender"
                        onChange={handle_text_input_change}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Membership Date
                </div>
                <div>
                    <input
                        type="text"
                        name="membership_date"
                        onChange={handle_text_input_change}
                        value={personData['membership_date']}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Head of Household
                </div>
                <div>
                    <input
                        type="checkbox"
                        className={Styles.pr_checkbox}
                        name="head_of_household"
                        checked={personData.head_of_household ? true : false}
                        onChange={(event) => handle_checkbox_change(event)}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Directory Include Email
                </div>
                <div>
                    <input
                        type="checkbox"
                        className={Styles.pr_checkbox}
                        name="directory_include_email"
                        checked={personData.directory_include_email ? true : false}
                        onChange={(event) => handle_checkbox_change(event)}
                    />
                </div>
            </div>

            <div className={Styles.pr_add_person_form_row}>
                <div>
                    Choose/Create Family
                </div>
                <div>
                    <select
                        className={Styles.pr_family_selector}
                        name="family_id"
                        value={personData['family_id']}
                        onChange={handle_text_input_change}
                        disabled={creatingFamily}
                    >
                        <option value=''>-</option>
                        {families.length > 0 && families.map((item, index) => (
                            <option key={index} value={item['family_id']}>{item['family_name'] + " (" + item['people_names'] + ")"}</option>
                            ))}
                    </select>
                    <button
                        className={Styles.pr_create_family_button}
                        onClick={start_creating_family}
                    >{creatingFamily ? "Cancel" : "Make New Family"}</button>
                </div>
            </div>

            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    New Family Name
                </div>
                <div>
                    <input
                        type="text"
                        name="family_name"
                        onChange={handle_text_input_change}
                        value={personData['family_name']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    Address 1
                </div>
                <div>
                    <input
                        type="text"
                        name="address_1"
                        onChange={handle_text_input_change}
                        value={personData['address_1']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    Address 2
                </div>
                <div>
                    <input
                        type="text"
                        name="address_2"
                        onChange={handle_text_input_change}
                        value={personData['address_2']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    City
                </div>
                <div>
                    <input
                        type="text"
                        name="city"
                        onChange={handle_text_input_change}
                        value={personData['city']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    State
                </div>
                <div>
                    <input
                        type="text"
                        name="state"
                        onChange={handle_text_input_change}
                        value={personData['state']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    Zip
                </div>
                <div>
                    <input
                        type="text"
                        name="zip"
                        onChange={handle_text_input_change}
                        value={personData['zip']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    Country
                </div>
                <div>
                    <input
                        type="text"
                        name="country"
                        onChange={handle_text_input_change}
                        value={personData['country']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    Home Phone
                </div>
                <div>
                    <input
                        type="text"
                        name="home_phone"
                        onChange={handle_text_input_change}
                        value={personData['home_phone']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    Wedding Year
                </div>
                <div>
                    <input
                        type="text"
                        name="wedding_year"
                        onChange={handle_text_input_change}
                        value={personData['wedding_year']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    Wedding Month
                </div>
                <div>
                    <input
                        type="text"
                        name="wedding_month"
                        onChange={handle_text_input_change}
                        value={personData['wedding_month']}
                    />
                </div>
            </div>
            <div className={creatingFamily ? Styles.pr_add_person_form_row : Styles.pr_hide}>
                <div>
                    Wedding Date
                </div>
                <div>
                    <input
                        type="text"
                        name="wedding_date"
                        onChange={handle_text_input_change}
                        value={personData['wedding_date']}
                    />
                </div>
            </div>





            <div className={Styles.pr_edit_person_button_row}>
                <button
                    className={Styles.pr_add_person_button}
                    onClick={props.cancel_adding_person}
                >Cancel</button>
                <button
                    className={Styles.pr_add_person_button}
                    onClick={save_new_person_data}
                    >Save</button>
            </div>

        </div>
    )
}
export default AddPersonForm;

