import React, {useEffect, useState} from "react";
import Styles from "./styles/pr.module.css";
import {date_to_ordinal, load_fetch, number_to_month_name} from "./pr_utils";

const EditFamily = (props) => {

    //DATA
    const [familyData, setFamilyData] = useState({
        family_id: props.data.family_id,
        family_name: props.data.family_name,
        address_1: props.data.address_1 ?? '',
        address_2: props.data.address_2 ?? '',
        city: props.data.city ?? '',
        state: props.data.state ?? '',
        zip: props.data.zip ?? '',
        country: props.data.country ?? '',
        home_phone: props.data.home_phone ?? '',
        wedding_year: props.data.wedding_year ?? '',
        wedding_month: props.data.wedding_month ?? '',
        wedding_date: props.data.wedding_date ?? '',
        created_by: props.data.created_by ?? '',
        last_edited_date: props.data.last_edited_date ?? '',
        last_edited_by: props.data.last_edited_by ?? '',
    });

    //AJAX FUNCTIONS
    const save_data = (event) => {
        event.stopPropagation();
        let url = 'Directory_Handler.php';
        let postData = {
            operation: 'UPDATE_FAMILY',
            data:familyData
        }
        load_fetch(url, postData, (data) => {
            if(!data['ERROR'] && !data['error']) {
                setFamilyData(data);
                props.update_family_data_after_save(data);
            } else {
                alert("Failure - Write failure handling.");
                console.log(data);
            }
        });
    }

    //GENERAL FUNCTIONS
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFamilyData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    //RENDERING
    return (
        <div className={Styles.pr_edit_family_form}>
            <h2>Edit Family Data</h2>
            <div>
                <label>
                    Family Name:
                    <input
                        type="text"
                        name="family_name"
                        value={familyData.family_name}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Address 1:
                    <input
                        type="text"
                        name="address_1"
                        value={familyData.address_1}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Address 2:
                    <input
                        type="text"
                        name="address_2"
                        value={familyData.address_2}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    City:
                    <input
                        type="text"
                        name="city"
                        value={familyData.city}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    State:
                    <input
                        type="text"
                        name="state"
                        value={familyData.state}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Zip:
                    <input
                        type="text"
                        name="zip"
                        value={familyData.zip}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Country:
                    <input
                        type="text"
                        name="country"
                        value={familyData.country}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Home Phone:
                    <input
                        type="text"
                        name="home_phone"
                        value={familyData.home_phone}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Wedding Year:
                    <input
                        type="text"
                        name="wedding_year"
                        value={familyData.wedding_year}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Wedding Month:
                    <input
                        type="text"
                        name="wedding_month"
                        value={familyData.wedding_month}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div>
                <label>
                    Wedding Date:
                    <input
                        type="text"
                        name="wedding_date"
                        value={familyData.wedding_date}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div className={Styles.pr_edit_cancel_button_row}>
                <button
                    className={Styles.pr_directory_edit_button}
                    onClick={(event) => save_data(event)}
                >SAVE</button>
                <button
                    className={Styles.pr_directory_cancel_button}
                    onClick={(event) => props.cancel_edits(event)}
                >CANCEL</button>
            </div>
        </div>
    );
}
export default EditFamily;