import React, {useEffect, useRef, useState} from "react";
import Styles from "./styles/pr.module.css"
import Welcome from "./images/1_welcome-700x300.jpg";
import Love from "./images/2_loving_god-700x300.jpg";
import Growing from "./images/3_growing-700x300.jpg";
import Sharing from "./images/4_sharing-700x300.jpg";

const Slideshow = (props) => {
    const [slideIndexState, setSlideIndexState] = useState(1);
    const slideIndex = useRef(1);

    useEffect(() => {
        showDivs();
        const timer = setInterval(() => {
            plusDivs(1);
        }, 10000);

        return () => clearInterval(timer);
    }, []);

    const plusDivs = (n) => {
        let newIndex = slideIndex.current + n;
        let imageCount = document.getElementsByClassName('slide_image').length;
        if(newIndex > imageCount) {
            newIndex = 1;
        } else if (newIndex < 1) {
            newIndex = imageCount;
        }
        slideIndex.current = newIndex;
        setSlideIndexState(newIndex);
        showDivs();
    }
    function currentDiv(n) {
        slideIndex.current = n;
        setSlideIndexState(n);
        showDivs();
    }

    function showDivs() {
        var i;
        var x = document.getElementsByClassName('slide_image');
        var dots = document.getElementsByClassName(Styles.pr_slideshow_button);
        for (i = 0; i < x.length; i++) {
            x[i].style.display = "none";
        }
        x[slideIndex.current - 1].style.display = "block";
    }
    const SlideshowButton = (props) => {
        if(props.selected == props.index) {
            return (
                <span
                    className={`${Styles.pr_slideshow_button} ${Styles.pr_slideshow_button_selected}`}
                    onClick={() => currentDiv(props.index)} title={props.selected} />
            )
        } else {
            return (
                <span
                    className={Styles.pr_slideshow_button}
                    onClick={() => currentDiv(props.index)} title={props.selected} />
            )
        }
    }

    return (
        <div className={Styles.pr_slideshow_holder}>
            <img className={`${Styles.pr_slideshow_image} slide_image`} src={Welcome} />
            <img className={`${Styles.pr_slideshow_image} slide_image`} src={Love} />
            <img className={`${Styles.pr_slideshow_image} slide_image`} src={Growing} />
            <img className={`${Styles.pr_slideshow_image} slide_image`} src={Sharing} />
            <div className={props.hide_controls ? Styles.pr_hide : Styles.pr_slideshow_controls}>
                <div className={Styles.pr_slideshow_left} onClick={() => plusDivs(-1)}>&#10094;</div>
                <div className={Styles.pr_slideshow_right} onClick={() => plusDivs(1)}>&#10095;</div>
                {[1,2,3,4].map(item => (
                    <SlideshowButton selected={slideIndexState} index={item} key={item} />
                ))}
            </div>
        </div>
    )
}
export default Slideshow;