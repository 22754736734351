import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";
import HeartlandLogo from "./images/heartlandaoc.jpg";

const WhatToExpect = (props) => {

    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div>
                <div className={Styles.pr_heading_medium}>What You'll See</div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>
                        When you come to church, you’ll be greeted at the door and given a bulletin (like a program
                        for the service). Feel free to sit anywhere in the sanctuary. There are no reserved seats,
                        and everyone will be glad you came.
                    </p>
                    <p>
                        We will <span className={Styles.pr_bold}>praise</span> God together through our worship.
                        When we meet together, the most common way we do this is by singing songs of praise to God.
                        We will sing some older traditional songs, and some modern songs. The most important thing
                        is that we are singing to God, our Father. He is the audience for our praise.
                    </p>
                    <p>
                        We will <span className={Styles.pr_bold}>pray</span> together. Sometimes we’ll pray for the
                        sick, perhaps for those who don’t know Jesus, or for our country. No matter what the need,
                        we’ll come to our God who loves to hear from his children (No one will put you on the spot
                        and ask you to pray out loud before others).
                    </p>
                    <p>
                        We’ll listen to the pastor <span className={Styles.pr_bold}>proclaim</span> some of God’s word,
                        the Bible, to us and explain what it means and how we can apply it to our lives.
                    </p>
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Children's Ministry</div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>
                        We have a children’s ministry that meets during the service. If you are a visitor with
                        children, you’re welcome to send them to the children’s ministry or to keep them with you.
                    </p>
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Music</div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>
                        We sing some traditional hymns with musical accompaniment (piano, organ). We sing some
                        contemporary worship songs as well. The most important thing, of course, is that while we
                        sing, we are glorifying God in our hearts.
                    </p>
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Dress</div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>
                        Dress as you prefer. You’ll find some in the church dressing formally and some dressing
                        casually. You’ll be welcome no matter how you choose to dress.
                    </p>
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>People</div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>
                        At our church, you’ll find people of all ages and of many different backgrounds. Everyone is
                        welcome. “Here there is no Gentile or Jew, circumcised or uncircumcised, barbarian, Scythian,
                        slave or free, but Christ is all, and is in all.” (Colossians 3:11 NIV)
                    </p>
                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Non-Christian Visitors</div>
                <div className={`${Styles.pr_block_quote} ${Styles.pr_spacing_one_and_a_half}`}>
                    <p>
                        Our worship service isn’t oriented toward Christians or towards non-Christians. It’s oriented
                        towards God, from a group of believers in the Lord Jesus Christ, creator and savior. We
                        welcome non-believers to come, visit, see what we’re about, hear the word of God, and we
                        welcome questions after the service.
                    </p>
                </div>
            </div>
        </div>
    </div>)
}
export default WhatToExpect;