import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const Testimony = (props) => {

    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div className={Styles.testimony_div}>
                The Bible tells Christians to be ready to give anyone an answer to the question of why we hope in Jesus,
                but many of us don't feel prepared. We're not public speakers and we aren't theologians. How do we
                go about being ready to give that answer?
            </div>
            <div className={Styles.testimony_div}>
                When Jesus delivered the demon-possessed man in the region of the Decapolis
                (<a href="https://www.biblegateway.com/passage/?search=Mark%205&version=NIV">Mark 5:1-20</a>),
                he didn't tell him to go back home and preach, or to give a theological defense of the gospel.
            </div>
            <div className={Styles.testimony_div}>
                No. He simply told him,
                "Go home to your own people and tell them how much the Lord has done for you, and how he has had mercy
                on you." The easiest way for a Christian to tell others about Jesus is to simply tell them what God
                has done personally in your life. Sometimes this is called "giving your testimony."
            </div>
            <div className={Styles.testimony_div}>
                The organization, Navigators, has put together some good materials to help Christians prepare their
                testimony. It's something anyone -- even those afraid of public speaking -- can do.
            </div>
            <div className={Styles.testimony_div}>
                4 Minute Worksheet -
                <a href="https://www.navigators.org/wp-content/uploads/2018/12/navtool-testimony.pdf">
                    https://www.navigators.org/wp-content/uploads/2018/12/navtool-testimony.pdf
                </a>
            </div>
            <div className={Styles.testimony_div}>
                How to share your testimony in three minutes -
                <a href="https://youtu.be/NkTFqbt3LXE">YouTube Video</a>
            </div>
        </div>
    </div>)
}
export default Testimony;